import { render, staticRenderFns } from "./interfaceSet.vue?vue&type=template&id=72aadc04&scoped=true&"
import script from "./interfaceSet.vue?vue&type=script&lang=js&"
export * from "./interfaceSet.vue?vue&type=script&lang=js&"
import style0 from "./interfaceSet.vue?vue&type=style&index=0&id=72aadc04&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.7@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72aadc04",
  null
  
)

export default component.exports